import React, { useRef, useState } from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Chip,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import emailjs from "@emailjs/browser";

import Header from "../layouts/Header";

function Form() {
  const form = useRef();
  const [formStatus, setFormStatus] = useState(false);
  const [selected, setSelected] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const selectionChangeHandler = (event) => {
    setSelected(event.target.value);
  };
  const [areatate, setAreaState] = React.useState("");

  const handleChange = (event) => {
    setAreaState(event.target.value);
  };

  const serviceId = process.env.REACT_APP_SERVICEID;
  const templateId = process.env.REACT_APP_TEMPLATEID;
  const publicId = process.env.REACT_APP_PUBLIC;

  // ------------ テスト機能 --------------------------

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(serviceId, templateId, form.current, publicId)
      .then((result) => {
        console.log(result.text);
        setFormStatus(true);
        window.location.href = "https://form-thanks.porte-co.jp/";
        // navigate("https://form-thanks.porte-co.jp/");
      })
      .catch((err) => console.log(err.text));
  };
  return (
    <div>
      <div>
        <Header />
      </div>
      <br />
      <br />
      <div className="formblock-part">
        <br />
        <br />

        <div className="form-title form-wrapper">
          <p className="form-wrapper-title">
            無料お見積もり訪問のご依頼
            <div className="under-line"></div>
            <div className="tel-info">やっぱりお電話での見積もりなら</div>
            <a href="tel:0120949383" className="tel-a-tag btn_tell form_telBtn">
              <div className="form-telBtn"></div>
            </a>
          </p>
        </div>
        <div className="form-wra-txt">
          ↓お見積もり訪問のご予約情報のご入力お願いします
        </div>
        <div className="estimate-part">
          <form ref={form} onSubmit={sendEmail}>
            <TextField
              required
              id="standard-required"
              label="お名前（漢字）（必須）"
              name="name-kanji"
              type="text"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <TextField
              required
              id="standard-required"
              label="お名前（ふりがな）（必須）"
              name="name"
              type="text"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <TextField
              required
              id="standard-required"
              label="メールアドレス（必須）"
              name="email"
              type="email"
              placeholder="example@email.com"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <TextField
              required
              id="standard-required"
              label="電話番号（必須）※ハイフンを入れてください"
              type="text"
              name="phone"
              placeholder="例）000-0000-0000"
              variant="standard"
              className="input-sm"
              fullWidth
            />

            <br />
            <br />
            <TextField
              required
              id="standard-basic"
              label="郵便番号（必須）※ハイフンを入れてください"
              type="text"
              name="area-num"
              placeholder="例）000-0000"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <TextField
              required
              id="standard-basic"
              label="ご住所（都道府県名〜番地までご記載ください）（必須）"
              type="text"
              name="area"
              placeholder=""
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <TextField
              required
              id="standard-basic"
              label="ご住所の続き（建物名・部屋番号をご記載ください）（必須）"
              type="text"
              name="area-extra"
              placeholder=""
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <FormControl>
              {/* <FormLabel id="demo-row-radio-buttons-group-label">
                エレベーターの有無
              </FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="area-opt"
              >
                <FormControlLabel
                  value="EVあり"
                  control={<Radio />}
                  label="エレベーターあり"
                />
                <FormControlLabel
                  value="EVなし"
                  control={<Radio />}
                  label="エレベーターなし"
                />
                <FormControlLabel
                  value="戸建て"
                  control={<Radio />}
                  label="戸建て"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <br />
            <p className="formblock-date">回収するもの（複数選択可）（必須）</p>
            <Select
              required
              id="standard-multiline-static"
              label="回収するもの"
              placeholder="回収するもの"
              multiple
              rows={4}
              name="collect"
              variant="standard"
              value={selected}
              className="input-sm"
              fullWidth
              vertualScroller
              MenuProps={MenuProps}
              onChange={selectionChangeHandler}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )}
            >
              <MenuItem value={"テレビ"}>テレビ</MenuItem>
              <MenuItem value={"ベッド"}>ベッド</MenuItem>
              <MenuItem value={"マットレス"}>マットレス</MenuItem>
              <MenuItem value={"洗濯機"}>洗濯機</MenuItem>
              <MenuItem value={"冷蔵庫"}>冷蔵庫</MenuItem>
              <MenuItem value={"電子レンジ"}>電子レンジ</MenuItem>
              <MenuItem value={"本棚"}>本棚</MenuItem>
              <MenuItem value={"食器棚"}>食器棚</MenuItem>
              <MenuItem value={"収納棚"}>収納棚</MenuItem>
              <MenuItem value={"テーブル"}>テーブル</MenuItem>
              <MenuItem value={"椅子"}>椅子</MenuItem>
              <MenuItem value={"ソファー"}>ソファー</MenuItem>
              <MenuItem value={"布団"}>布団</MenuItem>
              <MenuItem value={"衣裳ケース"}>衣裳ケース</MenuItem>
              <MenuItem value={"テレビ台"}>テレビ台</MenuItem>
              <MenuItem value={"ダンボール"}>ダンボール</MenuItem>
              <MenuItem value={"その他"}>その他</MenuItem>
              <FormHelperText>
                　　回収するものを選択してください（複数可）
              </FormHelperText>
            </Select>
            <br />
            <br />
            <TextField
              id="standard-multiline-static"
              label="その他の場合のお荷物詳細"
              multiline
              rows={3}
              name="else-collect"
              defaultValue=""
              placeholder="「回収するもの」で「その他」を選択された方は、詳細をご記入ください。"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <p className="formblock-date">
              見積もり訪問の希望日（第一希望）（必須）
            </p>
            <TextField
              required
              id="standard-basic"
              label=""
              type="date"
              placeholder=""
              name="date1"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            {/* <DatePicker
              label={'"year", "month" and "day"'}
              views={["month", "day"]}
              name="date1"
              variant="standard"
              fullWidth
              className="input-sm"
            />
            <br /> */}
            <br />
            <FormControl variant="standard" className="input-sm" fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                ご希望の時間
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="time1"
                label="ご希望の時間"
              >
                <MenuItem value="9-12時">9:00〜12:00</MenuItem>
                <MenuItem value="12-15時">12:00〜15:00</MenuItem>
                <MenuItem value="15-18時">15:00〜18:00</MenuItem>
                <MenuItem value="18-21時">18:00〜21:00</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <p className="formblock-date">見積もり訪問の希望日（第二希望）</p>
            <TextField
              id="standard-basic"
              label=""
              type="date"
              placeholder=""
              name="date2"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br />
            <br />
            <FormControl variant="standard" className="input-sm" fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                ご希望の時間
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="time2"
                label="ご希望の時間"
              >
                <MenuItem value="9-12時">9:00〜12:00</MenuItem>
                <MenuItem value="12-15時">12:00〜15:00</MenuItem>
                <MenuItem value="15-18時">15:00〜18:00</MenuItem>
                <MenuItem value="18-21時">18:00〜21:00</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                見積もり後、当日回収を希望されますか？
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="ontheday"
              >
                <FormControlLabel
                  value="金額次第で回収希望"
                  control={<Radio />}
                  label="希望する"
                />
                <FormControlLabel
                  value="見積もりのみ"
                  control={<Radio />}
                  label="希望しない"
                />
              </RadioGroup>
            </FormControl>
            {/* <FormControl variant="standard" className="input-sm" fullWidth>
              <FormControlLabel id="demo-row-radio-buttons-group-label">
                お支払い方法
              </FormControlLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="pay"
              >
                <FormControlLabel
                  value="現金"
                  control={<Radio />}
                  label="現金"
                />
                <FormControlLabel
                  value="クレジットカード"
                  control={<Radio />}
                  label="クレジットカード"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <br /> */}

            <TextField
              id="standard-multiline-static"
              label="その他お問い合わせ（任意）"
              multiline
              rows={3}
              name="message"
              defaultValue=""
              placeholder="まずは見積もりしてからお願いするか決めたい。など"
              variant="standard"
              className="input-sm"
              fullWidth
            />
            <br></br>
            <br />
            <br />
            <br />
            <input type="hidden" name="_recaptcha" id="_recaptcha" />
            <button type="submit" className="form-send-btn">
              送信する
            </button>
          </form>
        </div>
        {/* <Modal show={show} setShow={setShow} /> */}
        <br />
        {formStatus ? (
          <div className="alert alert-success">送信しています。</div>
        ) : (
          ""
        )}
        <br />
        <br />
        <div className="illus-porte"></div>
        <br />
        <p className="copyrights">©︎PORTE, Inc. Allrigths Reserved. 2022</p>
        <br />
      </div>
    </div>
  );
}

export default Form;
