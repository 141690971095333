import React from "react";
import { elastic as Menu } from "react-burger-menu";

function Header(props) {
  return (
    <div className="header-part">
      <a href="https://porte-co.jp" rel="TOPページ">
        <div className="head-icon"></div>
      </a>
      <div className="head-txt">
        <ul className="head-ul">
          <li className="head-txt-cnt">
            関東エリア／関西エリア／宮城／福島／愛知／静岡／長野／岐阜／岡山／広島／福岡／熊本全域
            <span className="head-txt-sm-none">
              <br />
              不用品、粗大ごみ回収、ごみ屋敷はお任せください！
            </span>
          </li>
          {/* <li className="head-tel">
            <a
              href="tel:0120949383"
              className="tel-a-tag btn_tell"
              id="btn_tell"
            >
              <div className="head-tel-img"></div>
            </a>
          </li>
          <li className="head-contact">
            <a
              href="https://form.porte-co.jp"
              rel="見積もりページ"
              className=""
            >
              <div className="head-contact-img"></div>
            </a>
          </li> */}
        </ul>
      </div>
      <div className="header-subpart">
        <ul className="header-sub-menu">
          <li className="header-sub-menu-li">
            <a href="#top">TOP</a>
          </li>
          <li className="header-sub-menu-li">
            <a href="https://porte-co.jp/#price-part">料金プラン</a>
          </li>

          <li className="header-sub-menu-li">
            <a href="https://porte-co.jp/#service-part">サービス</a>
          </li>

          <li className="header-sub-menu-li">
            <a href="https://porte-co.jp/#area-part">対応エリア</a>
          </li>

          <li className="header-sub-menu-li">
            <a href="https://porte-co.jp/#clean-part">回収の事例</a>
          </li>

          <li className="header-sub-menu-li">
            <a href="https://porte-co.jp/#about-part">会社概要</a>
          </li>
        </ul>
      </div>

      <div className="menu-bar">
        <Menu right>
          <a href="https://porte-co.jp/" rel="TOPページ" className="menu-item">
            ポルテのTOPページ
          </a>
          <a
            href="https://porte-co.jp/#service-part"
            rel="サービス"
            className="menu-item"
          >
            サービス
          </a>
          <a
            href="https://porte-co.jp/#price-part"
            rel="料金プラン"
            className="menu-item"
          >
            料金プラン
          </a>
          <a
            href="https://porte-co.jp/#area-part"
            rel="対応エリア"
            className="menu-item"
          >
            対応エリア
          </a>
          <a
            href="https://porte-co.jp/#clean-part"
            rel="回収の事例"
            className="menu-item"
          >
            回収の事例
          </a>
          <a
            href="https://porte-co.jp/#vioce-part"
            rel="お客様の声"
            className="menu-item"
          >
            お客様の声
          </a>
          <a href="https://form.porte-co.jp" className="menu-item">
            見積もり依頼
          </a>
        </Menu>
      </div>
    </div>
  );
}

export default Header;
